<template>
  <div class="active-wrap" :style="minHeight">
    <div class="active-box">
      <div class="side-inner">
        <img class="adorn1" src="@/assets/image/active/20240830-143855.png" alt="">
        <img class="adorn2" src="@/assets/image/active/20240830-143855.png" alt="">
        <img class="adorn3" src="@/assets/image/active/20240830-143855.png" alt="">
        <img class="adorn4" src="@/assets/image/active/20240830-143858.png" alt="">
        <img class="adorn5" src="@/assets/image/active/20240830-143906.png" alt="">
      </div>
      <div class="active-inner">
        <div class="banner-top">
          <img class="img-bg" src="@/assets/image/active/20240830-111031.png" alt="">
          <img class="img-font1" src="@/assets/image/active/20240830-111246.png" alt="">
          <img class="img-font2" src="@/assets/image/active/20240830-111254.png" alt="">
        </div>
        <div class="module-item">
          <img class="img-bg" src="@/assets/image/active/20240830-112358.png">
        </div>
        <div class="module-item">
          <img class="img-bg" src="@/assets/image/active/20240830-112707.png">
          <img class="img-btn" @click="goShopping" src="@/assets/image/active/20240830-112833.png">

          <img class="img-hand" src="@/assets/image/active/20240821-174716.png">
          <!-- 撒红包特效 -->
          <div class="red-packets">
            <img class="red-packet pake1" src="@/assets/image/active/20240821-180313.png">
            <img class="red-packet pake2" src="@/assets/image/active/20240821-180316.png">
          </div>
        </div>
        <div class="module-item">
          <img class="img-bg" src="@/assets/image/active/20240830-112718.png">
          <img class="img-cheap-price cheap1" src="@/assets/image/active/20240830-114422.png">
          <img class="img-cheap-price cheap2" src="@/assets/image/active/20240830-114422.png">

          <img class="img-btn  price img-price1" @click="goDetail(1, 213, 'AK0135')" src="@/assets/image/active/20240830-113809.png">
          <img class="img-btn price img-price2" @click="goDetail(1, 208, 'AK0129')" src="@/assets/image/active/20240830-113812.png">
        </div>
        <div class="module-item">
          <img class="img-bg" src="@/assets/image/active/20240830-112714.png">
          <img class="img-cheap-price cheap3" src="@/assets/image/active/20240830-140647.png">
          <img class="img-cheap-price cheap4" src="@/assets/image/active/20240830-140651.png">

          <img class="img-btn price img-price1" @click="goDetail(2, 11)" src="@/assets/image/active/20240830-114259.png">
          <img class="img-btn price img-price2" @click="goDetail(2, 238)" src="@/assets/image/active/20240830-114302.png">
        </div>
        <div class="module-item">
          <img class="img-bg" src="@/assets/image/active/20240830-113125.png">
        </div>
        
      </div>
      <div class="side-inner">
        <img class="adorn1 adorn6" src="@/assets/image/active/20240830-143855.png" alt="">
        <img class="adorn2 adorn7" src="@/assets/image/active/20240830-143855.png" alt="">
        <img class="adorn3 adorn8" src="@/assets/image/active/20240830-143855.png" alt="">
        <img class="adorn4 adorn9" src="@/assets/image/active/20240830-143858.png" alt="">
        <img class="adorn5 adorn10" src="@/assets/image/active/20240830-143906.png" alt="">
      </div>
    </div>
    <div class="foot">
      <div class="waves">
        <i class="public-i first"></i>
        <i class="public-i second"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      minHeight: {},
    }
  },
  // 事件处理器
  methods: {
    goShopping(){
      this.$router.push('/knowledgeMall/educationalCurriculum?code=09')
    },

    goDetail(type, productId, code){
      if (type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(productId + "") +
            "&code=" +
            code
        );
      } else if (type == 2) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(productId + "")
        );
      }
    },
    FunminHeight() {
      const obj = {
        minHeight: document.documentElement.clientHeight - 260 + "px",
      };
      this.minHeight = obj;
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.FunminHeight();
    const _this = this;
    window.onresize = function () {
      _this.FunminHeight();
    };

  },
  // 生命周期-实例销毁离开后调用
  destroyed () {
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.active-wrap{
  position: relative;
  padding-top: 60px;
  background-color: #FFB882;
  overflow: hidden;
  .active-box{
    display: flex;
  }
  .active-inner{
    width: 1200px;
    padding-bottom: 100px;
    .banner-top{
      margin-top: 12px;
      position: relative;
      .img-bg{
        width: 100%;
      }
      .img-font1{
        position: absolute;
        top: 33%;
        left: -100%;
        width: 34%;
        animation: banner1ing ease-in-out 1s alternate forwards ;
      }
      @keyframes banner1ing{
        0% {
          left: -100%;
          top: 33%;
          opacity: 0;
        }
        100% {
          left: 27%;
          top: 12%;
          opacity: 1;
        }
      }
      .img-font2{
        position: absolute;
        top: 25%;
        left: 120%;
        width: 36%;
        animation: banner2ing ease-in-out 1s alternate forwards ;
      }
      @keyframes banner2ing{
        0% {
          left: 120%;
          top: 25%;
          opacity: 0;
        }
        100% {
          left: 27%;
          top: 30%;
          opacity: 1;
        }
      }
    }
    .module-item {
      position: relative;
      overflow: hidden;
      z-index: 1;
      margin-top: 10px;
      .img-bg {
        width: 100%;
      }
      .img-btn {
        position: absolute;
        bottom: 1%;
        z-index: 9;
        left: 50%;
        width: 30%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
      .img-hand {
        position: absolute;
        right: 8%;
        top: 31%;
        width: 6%;
        animation: swing 1s ease-in-out infinite;
      }
      @keyframes swing {
        0%,
        100% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(20deg);
          /* 调整角度来改变摇摆幅度 */
        }
      }

      .red-packets {
        position: absolute;
        top: 54%;
        right: 8.3%;
        width: 5%;
        height: 28%;
      }

      .red-packet {
        position: absolute;
        width: 40%;
        height: auto;
      }

      .pake1 {
        top: 0;
        right: 65%;
        animation: fall 2s linear infinite;
      }

      .pake2 {
        right: 13%;
        animation: fall 3s linear infinite;
      }

      @keyframes fall {
        0% {
          transform: translateY(0) scale(1.2);
          opacity: 1;
        }

        100% {
          transform: translateY(50px) scale(0.7);
          opacity: 0;
        }
      }

      .img-cheap-price{
        position: absolute;
        top: calc(50% - 1px);
        width: 7%;
        animation: shakeAndZoom 2s linear forwards infinite;
      }
      @keyframes shakeAndZoom {
        0% {
          transform: scale(0.9);
        }

        25% {
          transform: scale(1.1);
        }

        50% {
          transform: scale(0.9);
        }

        75% {
          transform: scale(1.1);
        }

        100% {
          transform: scale(0.9);
        }
      }
      .cheap1{
        left: calc( 35% - 1px);
        transform: translate(-38%);
      }
      .cheap2{
        right: calc(15% + 4px);
        transform: translate(-15%);
      }
      .cheap3{
        top: calc(47% + 1px);
        left: calc( 35% - 1px);
        transform: translate(-38%);
      }
      .cheap4{
        top: calc(47% + 1px);
        right: calc(15% + 3px);
        transform: translate(-15%);
      }
      .price{
        width: 26%;
      }
      .img-price1{
        left: 20%;
        transform: translate(-20%, -35%);
      }
      .img-price2{
        left: 80%;
        transform: translate(-80%, -35%);
      }
    }
  }
  .side-inner{
    flex: 1;
    position: relative;
    .adorn1{
      position: absolute;
      max-width: 200px;
      width: 40%;
      left: 30%;
      top: 12%;
      opacity: .4;
      animation: rotate 4s linear infinite;
    }
    .adorn2{
      position: absolute;
      width: 18%;
      max-width: 90px;
      left: 55%;
      top: 18%;
      opacity: .7;
      animation: rotate2 2s linear infinite;
    }
    .adorn3{
      position: absolute;
      width: 23%;
      max-width: 130px;
      right: 18%;
      top: 21%;
      animation: rotate 3s linear infinite;
    }
    .adorn4{
      position: absolute;
      width: 7%;
      left: 22%;
      top: 30%;
      animation: fall4 6s linear infinite;
    }
    .adorn5{
      position: absolute;
      width: 8%;
      right: 30%;
      top: 41%;
      animation: fall4 4s linear infinite;
    }
    .adorn6{
      top: auto;
      width: 45%;
      bottom: 30%;
    }
    .adorn7{
      top: auto;
      width: 22%;
      bottom: 42%;
    }
    .adorn8{
      top: auto;
      left: 2%;
      width: 30%;
      bottom: 42%;
    }
    .adorn9{
      top: 20%;
    }
    .adorn10{
      top: 22%;
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg); /* 动画起始状态 */
      }
      to {
        transform: rotate(360deg); /* 动画结束状态 */
      }
    }
    @keyframes rotate2 {
      from {
        transform: rotate(0deg); /* 动画起始状态 */
      }
      to {
        transform: rotate(-360deg); /* 动画结束状态 */
      }
    }
    @keyframes fall4 {
      0% {
        transform: translateY(0) scale(1.4) rotate(0deg);
        opacity: 1;
      }

      100% {
        transform: translateY(340px) scale(0.5) rotate(-360deg);
        opacity: .6;
      }
    }
  }
  .foot {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 74px;
    width: 100%;
    .waves {
      height: 100%;
      overflow: hidden;
      position: relative;

      .public-i {
        display: block;
        height: 100%;
        position: absolute;
        width: 4600px;
        z-index: 3;
        left: 0;
      }

      .first {
        background: url(~@/assets/image/active/20240830-142613.png) top/cover;
        animation: freames1 10s cubic-bezier(0.14, 1, 0.87, 0.13) infinite
          alternate;
      }

      .second {
        background: url(~@/assets/image/active/20240830-142610.png) top/cover;
        animation: freames2 10s cubic-bezier(0.14, 1, 0.87, 0.13) infinite
          alternate;
        left: auto;
        right: 0;
      }

      @keyframes freames1 {
        0% {
          transform: translateZ(0);
        }

        100% {
          transform: translate3d(-50%, 0, 0);
        }
      }

      @keyframes freames2 {
        0% {
          transform: translateZ(0);
        }

        100% {
          transform: translate3d(50%, 0, 0);
        }
      }
    }
  }
}
</style>
